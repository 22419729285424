import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SpeechHandler, useSpeechStore } from '../lib/SpeechHandler';
import IntroDialog from './IntroDialog';
import Header from './Header';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ChatInterface from './ChatInterface';
import ActivityIndicator from './ActivityIndicator';

const API_BASE_URL = '/api';

// Message persistence utilities
const saveMessages = (messages) => {
  try {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  } catch (err) {
    console.error('Failed to save messages:', err);
  }
};

const loadMessages = () => {
  try {
    const saved = localStorage.getItem('chatMessages');
    return saved ? JSON.parse(saved) : [];
  } catch (err) {
    console.error('Failed to load messages:', err);
    return [];
  }
};

const SwarmChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // State
  const [isIntroOpen, setIsIntroOpen] = useState(() => {
    return !(location.state?.skipIntro || localStorage.getItem('accessToken'));
  });
  const [isConnected, setIsConnected] = useState(false);
  const [username, setUsername] = useState('');
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  
  // Refs
  const messagesEndRef = useRef(null);
  const speechHandlerRef = useRef(null);
  const isMounted = useRef(true);
  const sessionRestoredRef = useRef(false);
  
  // Speech store
  const { isTTSEnabled, isListening, setTTSEnabled, setListening } = useSpeechStore();

  // Initialize speech handler
  useEffect(() => {
    speechHandlerRef.current = new SpeechHandler();
    
    if (window.speechSynthesis) {
      const handleSpeechStart = () => setIsSpeaking(true);
      const handleSpeechEnd = () => setIsSpeaking(false);
      
      window.speechSynthesis.addEventListener('start', handleSpeechStart);
      window.speechSynthesis.addEventListener('end', handleSpeechEnd);
      
      return () => {
        window.speechSynthesis.removeEventListener('start', handleSpeechStart);
        window.speechSynthesis.removeEventListener('end', handleSpeechEnd);
      };
    }
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Handle intro dialog
  useEffect(() => {
    if (isConnected) {
      setIsIntroOpen(false);
    }
  }, [isConnected]);

  // Session restoration
  useEffect(() => {
    const restoreSession = async () => {
      console.log("=== SESSION CHECK START ===");
      
      // Skip if already connected or restored
      if (isConnected || sessionRestoredRef.current) {
        console.log("Session already active or restored, skipping");
        return;
      }

      const savedAccessToken = localStorage.getItem('accessToken');
      const savedUsername = localStorage.getItem('username');
      const savedMessages = loadMessages();
      
      if (savedAccessToken && savedUsername) {
        console.log("Found saved session, attempting to restore");
        try {
          // First verify the token with history endpoint
          const historyResponse = await fetch(`${API_BASE_URL}/history`, {
            headers: {
              'Authorization': `Bearer ${savedAccessToken}`
            }
          });

          if (!historyResponse.ok) {
            throw new Error('Invalid session');
          }

          // Token is valid, restore the session
          setAccessToken(savedAccessToken);
          setUsername(savedUsername);
          setIsConnected(true);
          sessionRestoredRef.current = true;

          // Restore messages if we have any
          if (savedMessages.length > 0) {
            console.log("Restoring saved messages:", savedMessages);
            setMessages(savedMessages);
          }

          console.log("Session successfully restored");

        } catch (error) {
          console.error("Session restoration failed:", error);
          // Clear all session data
          localStorage.removeItem('accessToken');
          localStorage.removeItem('username');
          localStorage.removeItem('chatMessages');
          setAccessToken(null);
          setUsername('');
          setIsConnected(false);
          sessionRestoredRef.current = false;
          setMessages([]);
          navigate('/login');
        }
      }
      console.log("=== SESSION CHECK COMPLETE ===");
    };

    restoreSession();
  }, []); // Only run on mount

  // Save messages when they change
  useEffect(() => {
    if (messages.length > 0) {
      saveMessages(messages);
    }
  }, [messages]);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleLogin = async (e, username, password) => {
    console.log("=== LOGIN START ===");
    if (e?.preventDefault) {
      e.preventDefault();
    }
    
    if (!username?.trim() || !password?.trim()) {
      setError('Username and password are required');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);
      
      console.log("Sending login request...");
      const response = await fetch(`${API_BASE_URL}/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Login failed');
      }

      const data = await response.json();
      console.log("Login response data:", data);
        
      // Update session state
      setAccessToken(data.access_token);
      setUsername(data.username);
      setIsConnected(true);
      setInputMessage('');
      setIsIntroOpen(false);
      sessionRestoredRef.current = true;

      // Set and save initial message if available
      if (data.initial_message) {
        console.log("Setting initial message:", data.initial_message);
        const newMessages = [{
          role: 'assistant',
          content: data.initial_message
        }];
        setMessages(newMessages);
        saveMessages(newMessages);
      }

      // Save session data
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('username', data.username);

      // Navigate home
      navigate('/', { replace: true });
      console.log("=== LOGIN COMPLETE ===");

    } catch (err) {
      console.error("Login error:", err);
      setError(err.message);
      sessionRestoredRef.current = false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async (e) => {
    e?.preventDefault();
    
    if (!inputMessage.trim() || !accessToken || isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const currentMessage = inputMessage;
      setInputMessage('');
      
      const response = await fetch(`${API_BASE_URL}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ content: currentMessage })
      });

      if (!response.ok) {
        throw new Error(`Failed to send message: ${response.statusText}`);
      }

      const data = await response.json();
      
      if (data.response) {
        const newMessages = [...messages, 
          { role: 'user', content: currentMessage },
          { role: 'assistant', content: data.response }
        ];
        setMessages(newMessages);
        saveMessages(newMessages);
        
        if (isTTSEnabled && speechHandlerRef.current) {
          try {
            await speechHandlerRef.current.speak(data.response);
          } catch (err) {
            console.error('TTS Error:', err);
          }
        }
      }
    } catch (err) {
      console.error("Send message error:", err);
      setError(`Failed to send message: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegistration = async (userData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Registration failed');
      }

      // After successful registration, log in
      await handleLogin(null, userData.username, userData.password);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  const handleLogout = async () => {
    try {
      if (accessToken) {
        await fetch(`${API_BASE_URL}/logout`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
      }
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      sessionRestoredRef.current = false;
      setIsConnected(false);
      setAccessToken(null);
      setMessages([]);
      setUsername('');
      setError(null);
      setInputMessage('');
      
      localStorage.removeItem('accessToken');
      localStorage.removeItem('username');
      localStorage.removeItem('chatMessages');
      
      if (speechHandlerRef.current && isListening) {
        speechHandlerRef.current.stopListening();
        setListening(false);
      }
      
      navigate('/login', { 
        replace: true,
        state: { skipIntro: true }
      });
    }
  };

  const toggleSpeechRecognition = () => {
    if (speechHandlerRef.current) {
      const newListeningState = speechHandlerRef.current.toggleSpeech((text, autoSend) => {
        setInputMessage(text);
        if (autoSend) {
          handleSendMessage({ preventDefault: () => {} });
        }
      });
      setListening(newListeningState);
    }
  };

  const toggleTTS = () => {
    if (window.speechSynthesis) {
      if (isTTSEnabled) {
        window.speechSynthesis.cancel();
      }
      setTTSEnabled(!isTTSEnabled);
    }
  };

  const renderContent = () => {
    if (isConnected) {
      return (
        <ChatInterface
          messages={messages}
          inputMessage={inputMessage}
          isLoading={isLoading}
          error={error}
          onInputChange={(e) => setInputMessage(e.target.value)}
          onSubmit={handleSendMessage}
          messagesEndRef={messagesEndRef}
        >
          <ActivityIndicator 
            isListening={isListening}
            isLoading={isLoading}
            isSpeaking={isSpeaking}
          />
        </ChatInterface>
      );
    }

    if (location.pathname === '/register') {
      return (
        <RegisterForm
          onRegister={handleRegistration}
        />
      );
    }

    return (
      <LoginForm
        username={username}
        isLoading={isLoading}
        isListening={isListening}
        isTTSEnabled={isTTSEnabled}
        isSpeaking={isSpeaking}
        error={error}
        onUsernameChange={(e) => setUsername(e.target.value)}
        onSubmit={handleLogin}
        onToggleSpeech={toggleSpeechRecognition}
        onToggleTTS={toggleTTS}
      />
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {!isConnected && <IntroDialog isOpen={isIntroOpen} onOpenChange={setIsIntroOpen} />}
      
      <Header 
        isConnected={isConnected}
        isLoading={isLoading}
        isListening={isListening}
        isTTSEnabled={isTTSEnabled}
        isSpeaking={isSpeaking}
        onLogout={handleLogout}
        onToggleSpeech={toggleSpeechRecognition}
        onToggleTTS={toggleTTS}
      />

      <div className="flex-1 container mx-auto max-w-4xl p-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default SwarmChat;
