import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card } from "./ui/card";

const EmailVerification = () => {
  const [status, setStatus] = useState('verifying');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const token = params.get('token') || location.pathname.split('/').pop();
        
        if (!token) {
          setStatus('error');
          return;
        }

        const response = await fetch(`/api/verify-email/${token}`);

        if (response.ok) {
          setStatus('success');
          // Redirect to login after 3 seconds
          setTimeout(() => {
            navigate('/login', { 
              state: { message: 'Email verified successfully. You can now log in.' }
            });
          }, 3000);
        } else if (response.status === 400) {
          const data = await response.json();
          // Token already used means the email was already verified
          if (data.detail === "Token already used") {
            setStatus('success');
            setTimeout(() => {
              navigate('/login', { 
                state: { message: 'Email was already verified. You can log in.' }
              });
            }, 3000);
          } else {
            setStatus('error');
          }
        } else {
          setStatus('error');
        }
      } catch (error) {
        setStatus('error');
      }
    };

    verifyEmail();
  }, [location.search, location.pathname, navigate]);

  return (
    <Card className="p-6">
      <div className="text-center space-y-4">
        {status === 'verifying' && (
          <>
            <h2 className="text-2xl font-bold">Verifying Your Email</h2>
            <p className="text-gray-600">Please wait while we verify your email address...</p>
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          </>
        )}

        {status === 'success' && (
          <>
            <h2 className="text-2xl font-bold text-green-600">Email Verified!</h2>
            <p className="text-gray-600">
              Your email has been verified successfully. Redirecting you to login...
            </p>
          </>
        )}

        {status === 'error' && (
          <>
            <h2 className="text-2xl font-bold text-red-600">Verification Failed</h2>
            <p className="text-gray-600">
              We couldn't verify your email. The link may be invalid or expired.
            </p>
            <button
              onClick={() => navigate('/login')}
              className="mt-4 text-blue-500 hover:text-blue-600"
            >
              Return to Login
            </button>
          </>
        )}
      </div>
    </Card>
  );
};

export default EmailVerification;
